import { useRef} from 'react';
import { FaBars, FaTimes } from "react-icons/fa"
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import APmain from './AP_main';
import APrussia from './AP_russia';
import APukraine from './AP_ukraine';
import APusa from './AP_usa';
import Polit_grund from './Polit_Grund';
import Wirtschaft from './Wirtschaft';
import Homepage from './Homepage';
import Quellen from './Quellen';
import '../App.css';
import App from '../App';

function Header() {

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  }

  return (
    <BrowserRouter>
    <header className='navbar'>
      <Link to="/" className='logo'>China & Ukrainekrieg</Link>
      <nav ref={navRef}>
        
        <Link to="/Aussenpolitik"><a>Auslandsbeziehungen</a></Link>
        <Link to="/Wirtschaft"><a>Wirtschaft</a></Link>
        <Link to="/PolitischeGrundhaltung"><a>Politische Grundhaltung</a></Link>
        <Link to="/Quellen"><a>Quellen</a></Link>
          <button className='nav-btn nav-close-btn' onClick={showNavbar}>
              <FaTimes/>
          </button>
      </nav>
      <button className='nav-btn' onClick={showNavbar}>
          <FaBars/>
      </button>
    </header>
    <Routes>
      <Route path="/Aussenpolitik" element={<APmain />} />
      <Route path="/Aussenpolitik_Russland" element={<APrussia />} />
      <Route path="/Aussenpolitik_Ukraine" element={<APukraine />} />
      <Route path="/Aussenpolitik_USA" element={<APusa />} />
      <Route path="/Wirtschaft" element={<Wirtschaft />} />
      <Route path="/PolitischeGrundhaltung" element={<Polit_grund />} />
      <Route path="/Quellen" element={<Quellen />} />
      <Route path="/" element={<Homepage />} />
    </Routes>
    </BrowserRouter>
  );
}

export default Header;