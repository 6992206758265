import React from "react";
import '../App.css';

function ap_ukraine(){
    document.title = "CCP-UA: Beziehungen mit Ukraine"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 24.11.2022</p>
                <p className="infoBlock">Geändert: 2.1.2023</p>
            </div>
            <h1 className="title">Beziehungen zwischen China und der Ukraine</h1>
            <p className="textBlock">Die diplomatische Beziehungen zwischen der Ukraine und China haben offiziell im Jahre 1992 angefangen. Obwohl dessen politisches Verhältnis schon immer für beide Nationen sehr wichtig war, ist es eine sehr komplexe Beziehung, welche während all diesen Jahren sehr oft ihre Seite gewechselt hat. Die beiden Länder verstehen sich als «strategische Partner» und verfügen über mehr als 240 bilateral unterzeichnete Dokumente, welche zeigen, wie wichtig deren Beziehung ist. Ein Dokument, welches jedoch sehr essenziell für die Aussenpolitik Chinas und der Ukraine, ist die gemeinsame Erklärung über den Aufbau und die Entwicklung der Beziehungen zwischen den beiden Nationen. Ebenfalls hat China mit der Ukraine eine Nukleare Sicherheits-Garantie im Jahre 1994 unterzeichnet, welche im Falle einer nuklearen Eskalierung der Ukraine helfen soll. </p>
            <p className="textBlock">Als jedoch Viktor Yushchenko der Präsident der Ukraine war und den Besuch eines taiwanesischen Offizier für ein Meeting im Jahre 2005 erlaubt hatte, haben sich die Beziehungen zwischen den zwei Nationen verschlechtert. Als später Viktor Yanukovych den Amt als Präsident der Ukraine übernahm, haben sich dessen Beziehungen wieder verbessert.  </p>
            <img src={ process.env.PUBLIC_URL + "/images/china_ukraine_2010.jpg"} className="textImage"/>
            <p className="textBlock">Die Ukraine ist ein sehr wichtiger Handelspartner Chinas, vor allem seit dem Jahre 2008. Das Verhältnis ist so wichtig, dass China der grösste Handelspartner der Ukraine ist. Z.B. liefert die Ukraine den grössten Teil der Waffen, welche im Land produziert werden, an China. Während der Pandemie im Jahre 2009 hat China medizinisches Material wie z.B. Masken, Desinfektionsmittel, usw. im Wert von 500'000 $ an die Ukraine gegeben. Die Investitionen von China in der Ukraine sind sehr wichtig, denn China ist auf dem Platz 56 der grössten Investoren in der Ukraine. Der Handel zwischen den zwei Nationen ist sehr stabil und dies obwohl es noch über alte Verträge abläuft. Die Güter, die diese Handelsbeziehung prägen, sind vor allem Rohstoffe und Industriegüter. Ebenfalls zeigt die Ukraine für das riesige Seidenstrassenprojekt von China ein grosses Interesse. </p>
            <p className="textBlock">In der Realität ist der strategische Charakter dessen Beziehung schon seit 5 Jahren nicht mehr sichtbar. Seit dem Machtwechsel (Petro Poroschenko) in der Ukraine im Jahre 2014 ist die Abkühlung des bilateralen Dialogs stark sichtbar. Während der Zeit von Poroschenko gab es kaum Besuche der Staatsoberhäuptern. Die einzige Besuche die es gab, waren am Rande von Gipfeltreffen.  </p>
            <img src={ process.env.PUBLIC_URL + "/images/poroschenko.jpg"} className="textImage"/>
            <p className="textBlock">In Bezug zum aktuellen Krieg zwischen der Ukraine und Russland behält China seine Neutralität. Sie wollen und haben bis anhin keine klare Meinung im Bezug zum Krieg geäussert. Zelensky hat sogar schon gesagt, dass er mit der Position von China zufrieden sei. Die Neutralität Chinas ist sogar sehr verständlich, da sie nicht nur mit der Ukraine ein wichtiges Verhältnis hat, sondern auch mit Russland und somit ist es für sie wichtig, diese Beziehungen beibehalten zu können. Ebenfalls wollen sie ihr Verhältnis mit dem Westen aus Interessensgründen nicht zerstören und somit liegen sie in einer sehr schwierigen Situation. Was sie aber geäussert haben, ist, dass sie die russische Annektierung von Krim nicht anerkennen. Selenskyj und Xi Jinping haben eine gute Beziehung und dies bedeutet, dass das Verhältnis der zwei Nationen umso stabiler ist.  </p>
        </div>
    );

}

export default ap_ukraine;