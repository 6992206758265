import React from "react";
import '../App.css';


function wirtschaft(){
    document.title = "CCP-UA: Wirtschaft"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 24.11.2022</p>
                <p className="infoBlock">Geändert: 2.1.2023</p>
            </div>
            <h1 className="title">Chinesische Wirtschaft</h1>
            <p className="textBlock">Der Ukrainekrieg hat grosse Auswirkungen auf die europäische Wirtschaft, indem die Energie-Industrie komplett verändert wird und viele aus den kriegsbetroffenen Ländern flüchten. In China sieht dies etwas anders aus. Zwar gibt es momentan ökonomische Schwierigkeiten in China, jedoch wurden diese nicht hauptsächlich vom Krieg verursacht. Ein Grund für diesen Unterschied zwischen Europa und China ist die Anzahl Sanktionen, die sie durchgeführt haben. China ist eher pro-Russland und will somit keine Sanktionen erlassen, macht aber trotzdem bei einigen mit, zum Teil vielleicht auch weil sie sehen, dass Handel mit Russland nicht mehr so profitabel ist. Was die Sanktionen jedoch bedeuten, sind höhere Preise für Energieimports. China ist angewiesen auf Energie (hauptsächlich Kohle) von westlichen Ländern, und dessen Preise steigen dadurch, dass russische Energie kaum mehr nach Europa fliesst. Dies hat einen Einfluss auf ihre Emissionsziele bis 2025. Sie haben sich im März 2022 erlaubt, eine Zeit lange dieses Ziel zu vernachlässigen, damit sie flexibler mit den steigenden Energiepreisen umgehen können. Trotz dieser Vernachlässigung wollen sie ihr Ziel bis 2025 einhalten.</p>
            <img src={ process.env.PUBLIC_URL + "/images/coal.jpg"} className="textImage"/>
            <p className="textBlock">Die politische Haltung zwischen Russland und China hat sich seit Februar 2022 verändert, was auch auf die Wirtschaft einen Einfluss hat. Zum Beispiel zögern chinesische Firmen beim Wirtschaftsverkehr in Russland, weil sie ihr Image beibehalten wollen. Es könnte sein, dass russische Unternehmen gerne ihre Produkte billiger verkaufen würden, damit sie ihre Waren überhaupt international losbringen können. Somit entsteht ein attraktiver Markt für Länder, die Russland nicht sehr drastisch sanktioniert haben. Dieser Markt hat jedoch Folgen für diejenigen, die Profit über Moralität stellen. Würden chinesische Grossfirmen viel Handel mit Russland treiben, könnten sie von Europa und anderen westlichen Ländern weggetrieben werden, sei es von den Regierungen oder doch von dem Publikum. Trotzdem ist der Handel zwischen Russland und China gestiegen. Dies liegt daran, dass die Anzahl kleinerer Unternehmen in Russland angestiegen ist. Diese können die europäischen Firmen, die Russland wegen dem Krieg verlassen haben, ersetzen, da die chinesischen Firmen nicht viel ausserhalb China Geschäfte betreiben und somit weniger anfällig für Boykotte sind.</p>
            <p className="textBlock">Die europäischen Sanktion auf Russland haben noch viel mehr Einfluss auf die chinesische Wirtschaft. Die Sanktion führen dazu, dass Europa geschwächt ist, da ein Energiemangel entstand. Die USA verbraucht viele Ressourcen, um Europa stark zu halten und die Ukraine im Krieg zu unterstützen. Somit haben die Vereinigten Staaten weniger Möglichkeiten, sich in den lokalen Markt in Südasien einzumischen, sodass China sehr gute Voraussetzungen hat, einen ausgeprägten Handel mit Südostasiatischen Ländern wie Thailand, Malaysia, Vietnam und anderen herauszuarbeiten. Weiterhin gut für den chinesischen Markt ist der Fakt, dass Russland wegen den Sanktionen und chinesischem Druck vieles mit dem Renminbi, der chinesischen Währung, bezahlt. Dies müssen die Russen in Kauf nehmen, da sonst internationaler Handel sehr klein wäre und die Wirtschaft Russlands noch mehr abstürzen würde, als sie jetzt schon macht.</p>
            <img src={ process.env.PUBLIC_URL + "/images/ruble.jpg"} className="textImage"/>
            <p className="textBlock">Die Welt kann vom Ukrainekrieg viel lernen. Zum Beispiel sehen wir, wie ineffektiv russische Panzerung, Militärausrüstung und Raketen sind. Tag für Tag beweist sich, dass Russland keine Chance gegen die Westmächte hätte. Wie kann China davon jetzt profitieren? Länder, die Waffen aus Russland kaufen, können entweder nicht mehr, da durch den Ukrainekrieg alle russischen Reserven benötigt werden, oder steigen zur nächst besten, nicht westlichen Option um und diese ist China. Auch in der Wirtschaftlichen Welt steigen einige Länder zur chinesischen Variante der internationalen Geldtransaktion, CIPS, um, obwohl diese immer noch auf der amerikanischen SWIFT basiert. China versucht diese Abhängigkeit zu entfernen und ihr System generell zu verbessern um noch mehr Kunden anzulocken. Dabei müssen sie jedoch aufpassen, dass sie Russland nicht zu viel unterstützen, denn die USA wird jederzeit hinter China mit Sanktionen drohen, aber auch dafür bereitet sich China vor. Die chinesische Regierung sieht, dass sie sich von den USA entkoppeln müssen, denn die Gefahr vor dem Absturz ihrer Wirtschaft im Falle von einer grossen Sanktionswelle aus dem Westen ist gross. Schon vor der Ukrainekrise haben sie daran gedacht und Pläne geschmiedet, die jetzt nur noch schneller ausgeführt werden sollen. Diese gleiche Angst vor einer Sanktionswelle haben auch andere Länder und so erhoffen sich einige, mit China mehr Sicherheit zu erlangen.</p>
            <img src={ process.env.PUBLIC_URL + "/images/tonk.jpg"} className="textImage"/>
        </div>
    );

}

export default wirtschaft;