import React from 'react'
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function() {
  return (
    <div className='slideshowContainer'>
        <Carousel>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src={ process.env.PUBLIC_URL + "/images/xi_jinping.jpg"}
          alt="First slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img
          className="d-block w-100"
          src={ process.env.PUBLIC_URL + "/images/china_lockdown.jpg"}
          alt="Second slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={ process.env.PUBLIC_URL + "/images/china_russia.jpg"}
          alt="Third slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={ process.env.PUBLIC_URL + "/images/china_street.jpg"}
          alt="Third slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={ process.env.PUBLIC_URL + "/images/china_ukraine_2010.jpg"}
          alt="Third slide"
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>

    </Carousel>
    </div>
  )
}
