import React from "react";
import '../App.css';

function ap_usa(){
    document.title = "CCP-UA: Beziehungen mit USA"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 24.11.2022</p>
                <p className="infoBlock">Geändert: 2.1.2023</p>
            </div>
            <h1 className="title">Beziehungen zwischen China und der USA</h1>
            <p className="textBlock">Ursprünglich war die Beziehung zwischen China und der USA relativ gut im Gegensatz zu anderen Nationen, wie z.B. aus Europa, welche China als Staat unterdrückten. Erst später, mit einigen historischen Geschehnissen, haben sich die Beziehungen der beiden Staaten verschlechtert.  </p>
            <p className="textBlock">In der Volksrepublik China entstand später eine Phase der Reform- und Öffnungspolitik, wobei jedoch die kommunistische Partei Chinas weiterhin die Macht behielt. Dies führte zum Tian’anmen Massaker, welcher im Jahre 1989 stattfand und wo eine demokratische Protestbewegung, welche vor allem von Studenten geführt wurde, gewaltsam niedergeschlagen wurde. Dieses Massaker erschwerte die Beziehung mit den Vereinigten Staaten massiv.  </p>
            <img src={ process.env.PUBLIC_URL + "/images/tian'anmen.jpg"} className="textImage"/>
            <p className="textBlock">Als im Jahre 2016 Donald Trump zum Präsident der USA gewählt wurde, besagte er, dass China einseitig Interessen bevorzugte. Die Vereinigten Staaten warfen später der Volksrepublik vor, dass sie den geistigen Eigentum missachteten, dass sie unfaire Bedingungen im gegenseitigen Handel aufstellten und dass sie einen Technologiediebstahl betreiben.  </p>
            <p className="textBlock">Als die Covid-19 Pandemie im Jahre 2019/2020 ausbrach, warf Donald Trump China vor, verantwortlich für den Ausbruch der Pandemie und somit für die daraus resultierenden Folgen für die ganze Welt zu sein. Konsequenterweise reduzierten die beiden Grossmächten die zugelassenen Auslandskorrespondenzen aus dem jeweiligen anderen Staat.</p>
            <img src={ process.env.PUBLIC_URL + "/images/china_lockdown.jpg"} className="textImage"/>
            <p className="textBlock">Diese Entscheidungen und Aussagen verschärften die Beziehungen der beiden Staaten drastisch, sodass z.B. im Juli 2020 die USA die Schliessung des chinesischen Konsulats in Houston anordnete. Die Begründung für diese Aktion, war, dass die chinesische Diplomaten die Gesetze und Vorschriften der USA missachteten. Die Antwort von der Regierung von Xi Jinping war die Anordnung der Schliessung des US-Konsulats in Chengdu.  </p>
            <p className="textBlock">Das Verhältnis zwischen den Vereinigten Staaten und China verläuft auch aktuell weiterhin recht verschärft. Es verschlechterte sich umso mehr als am 2. August 2022 die US-Politikerin Nancy Pelosi, die höchstrangige Vertreterin der USA seit 25 Jahren, Taiwan besuchte. Dieser Besuch hat China gar nicht gefallen, da sie es als eine Aktion der Einmischung der USA in den Taiwan-Konflikt ansahen. Dieser Besuch war schon im Vorhinein vom Staatsrat der Volksrepublik China verurteilt worden, wurde jedoch trotzdem geführt. Daraufhin veranstaltete die chinesische Armee eine riesige mehrtägige Übung zur Abrieglung Taiwans, bei welcher unter anderem auch Raketen aktiv gebraucht wurden und ins Meer um Taiwan gefeuert wurden.  </p>
            <img src={ process.env.PUBLIC_URL + "/images/nancy_pelosi.jpg"} className="textImage"/>
        </div>
    );

}

export default ap_usa;