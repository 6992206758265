import React from "react";
import '../App.css';


function quellen(){
    document.title = "CCP-UA: Quellen"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 15.12.2022</p>
                <p className="infoBlock">Geändert: 2.1.2023</p>
            </div>
            <h1 className="title">Quellen</h1>
            <p className="textBlock">Benutzte Artikel:
            <ul className="quellen">
                <li className="quelle">Beziehung mit Russland: 
                    <ul className="quellen">
                        <li><a className="link" href="https://en.wikipedia.org/wiki/China%E2%80%93Russia_relations">https://en.wikipedia.org/wiki/China%E2%80%93Russia_relations</a></li>
                        <li><a className="link" href="https://www.fmprc.gov.cn/mfa_eng/wjdt_665385/2649_665393/200107/t20010724_679026.html">https://www.fmprc.gov.cn/mfa_eng/wjdt_665385/2649_665393/200107/t20010724_679026.html</a></li>
                        <li><a className="link" href="https://www.china-briefing.com/news/china-russia-bilateral-trade-is-worlds-fastest-growing-opportunity-corridor/">https://www.china-briefing.com/news/china-russia-bilateral-trade-is-worlds-fastest-growing-opportunity-corridor/</a></li>
                        <li><a className="link" href="https://www.statista.com/statistics/1100708/russia-leading-foreign-trade-partners-by-volume/">https://www.statista.com/statistics/1100708/russia-leading-foreign-trade-partners-by-volume/</a></li>
                        <li><a className="link" href="https://www.wionews.com/world/russia-recognises-taiwan-as-part-of-china-opposes-islands-independence-450437">https://www.wionews.com/world/russia-recognises-taiwan-as-part-of-china-opposes-islands-independence-450437</a></li>
                        <li><a className="link" href="https://web.archive.org/web/20090620111314/http://news.xinhuanet.com/english/2009-06/18/content_11558133.htm">https://web.archive.org/web/20090620111314/http://news.xinhuanet.com/english/2009-06/18/content_11558133.htm</a></li>
                        <li><a className="link" href="https://thediplomat.com/2010/04/why-china-snubs-russian-arms/">https://thediplomat.com/2010/04/why-china-snubs-russian-arms/</a></li>
                        <li><a className="link" href="https://www.foxnews.com/world/chinese-news-media-rules-russia-ukraine-coverage">https://www.foxnews.com/world/chinese-news-media-rules-russia-ukraine-coverage</a></li>
                    </ul>
                </li>
                <li className="quelle">Beziehung mit Ukraine:
                    <ul className="quellen">
                        <li><a className="link" href="https://en.wikipedia.org/wiki/China%E2%80%93Ukraine_relations">https://en.wikipedia.org/wiki/China%E2%80%93Ukraine_relations</a></li>
                        <li><a className="link" href="https://chinaobservers.eu/the-enemy-of-my-friend-remains-my-friend-chinas-ukraine-dilemma/">https://chinaobservers.eu/the-enemy-of-my-friend-remains-my-friend-chinas-ukraine-dilemma/</a></li>
                        <li><a className="link" href="https://www.cfr.org/in-brief/whats-stake-chinas-economic-relationship-ukraine">https://www.cfr.org/in-brief/whats-stake-chinas-economic-relationship-ukraine</a></li>
                    </ul>
                </li>
                <li className="quelle">Beziehung mit USA:
                    <ul className="quellen">
                        <li><a className="link" href="https://www.focus.de/politik/ausland/konfrontation-china-usa-im-ueberblick_id_12245586.html">https://www.focus.de/politik/ausland/konfrontation-china-usa-im-ueberblick_id_12245586.html  </a></li>
                    </ul>
                </li>
                <li className="quelle">Chinesische Wirtchaft:
                    <ul className="quellen">
                        <li><a className="link" href="https://www.eastasiaforum.org/2022/04/18/the-russia-ukraine-crisis-is-bad-news-for-chinas-economy/">https://www.eastasiaforum.org/2022/04/18/the-russia-ukraine-crisis-is-bad-news-for-chinas-economy/</a></li>
                        <li><a className="link" href="https://www.eastasiaforum.org/2022/06/16/ukraines-losses-are-chinas-gains/">https://www.eastasiaforum.org/2022/06/16/ukraines-losses-are-chinas-gains/</a></li>
                        <li><a className="link" href="https://thediplomat.com/2022/08/amid-russia-ukraine-war-china-could-dominate-the-value-arms-market/">https://thediplomat.com/2022/08/amid-russia-ukraine-war-china-could-dominate-the-value-arms-market/</a></li>
                        <li><a className="link" href="https://foreignpolicy.com/2022/04/15/china-western-financial-power-ukraine/">https://foreignpolicy.com/2022/04/15/china-western-financial-power-ukraine/</a></li>
                    </ul>
                </li>
                <li className="quelle">Politische Grundhaltung:
                    <ul className="quellen">
                        <li><a className="link" href="https://en.wikipedia.org/wiki/China_and_the_2022_Russian_invasion_of_Ukraine ">https://en.wikipedia.org/wiki/China_and_the_2022_Russian_invasion_of_Ukraine </a></li>
                        <li><a className="link" href="https://www.deutschlandfunkkultur.de/russland-china-feindbild-demokratie-100.html">https://www.deutschlandfunkkultur.de/russland-china-feindbild-demokratie-100.html</a></li>
                        <li><a className="link" href="https://www.wilsoncenter.org/blog-post/chinas-strategic-calculations-russia-ukraine-war">https://www.wilsoncenter.org/blog-post/chinas-strategic-calculations-russia-ukraine-war </a></li>
                    </ul>
                </li>
            </ul>
            </p>
            <p className="textBlock">Benutzte Bilder:</p>
            <ul className="quellen">
                <li><a className="link" href="https://quest.eb.com/">Britannica ImageQuest</a></li>
                <li><a className="link" href="https://visual.keystone-sda.ch/home">Keystone-SDA</a></li>
                <li><a className="link" href="https://unsplash.com/">Unsplash</a></li>
            </ul>
        </div>
    );

}

export default quellen;