import React from "react";
import '../App.css';

function ap_russia(){
    document.title = "CCP-UA: Beziehung mit Russland"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 24.11.2022</p>
                <p className="infoBlock">Geändert: 2.1.2023</p>
            </div>
            <h1 className="title">Beziehungen zwischen China und Russland</h1>
            <img src={ process.env.PUBLIC_URL + "/images/putin_china.jpg"} className="textImage"/>
            <p className="textBlock">Die Russische Föderation und China haben eine langjährige und sehr wichtige Beziehung, sei es im Handel, wie aber auch im strategischen Charakter. Ihre Beziehung spannt schon über 100 Jahre und somit auch verschiedene Regierungssysteme. Die Beziehung der beiden Nationen begann jedoch hauptsächlich erst mit dem zaristischen Regierungssystem bzw. mit dem chinesischen Kaiserreich. Das Verhältnis zwischen den zwei Nationen war nicht schon seit immer gut. Zur Zeit der Sowjetunion bestand eine gewisse Feindseligkeit zwischen den zwei Staaten, welche sich mit dem Fall der Sowjetunion wieder verbessert hat. Jedoch hat die Sowjetunion auch während dieser Feindseligkeit China mehrmals unterstützt und dies obwohl sie immer wieder Konflikte wegen der Mandschurei hatten. Somit fand eine Jahrzehnte lange Feindseligkeit sein Ende und aus diesem entstand ein gewisses Bedürfnis für eine Partnerschaft. Im Jahre 1950 schlossen die beiden Nationen einen Freundschaftsvertrag ab, welches ein Bündnis im Falle einer japanischen oder amerikanischer Aggression bedeutete und womit eine Wirtschaftshilfe für China mitinbegriffen war. Nur wenig später, zwischen 1956 und 1959, waren schon die ersten Brüche in der Koalition zu sehen. </p>
            <p className="textBlock">Da während der Sowjetunion die chinesischen Grenzen immer weiter in Richtung Asien gingen, mussten sie einen Vertrag unterzeichnen um deren Gebietsansprüche zu klären. Dies war auch das erste bilaterale Abkommen zwischen den zwei Staaten. Im Jahre 2001 wurde ein Kooperationsvertrag von den zwei Parteien unterzeichnet und im letzten Jahr wurde dieser für 5 Jahren verlängert. Diese Beziehung hat ihre Wichtigkeit für diese Länder, vor allem im Bereich des Handels. Dies zeigen uns vor allem Fakten, wie z.B. die Tatsache, dass China seit 2010 der wichtigste Handelspartner Russlands ist. Ebenfalls ist China, sowie auch Russland, Mitglied des BRICS (ökonomische Partnerschaft von Brasilien, Russland, Indien, China und Süd Afrika). China ist ausserdem z.B. auch der grösste Verbraucher von russischem Gas, was vor allem aus dem Grunde passiert, dass die chinesische, stark wachsende Ökonomie einen höheren Import von Energie gebraucht und somit die Nation unter starkem Druck setzt.  </p>
            <img src={ process.env.PUBLIC_URL + "/images/china_pipeline.jpg"} className="textImage"/>
            <p className="textBlock">Seit langem hat China auch eine riesige Menge an Waffen von Russland importiert, was heutzutage ebenfalls noch der Fall ist. Aktuell wird jedoch ein grosser Teil der Waffen in China entwickelt und fabriziert. Was die Chinesen weiterhin von den Russen konsumieren, sind vor allem militärische Produkte wie z.B. Kampfjet-Motoren. China versucht somit im militärischen Bereich unabhängig zu werden und dies hat sich in den letzten Jahren deutlich gezeigt.  </p>
            <p className="textBlock">Seit 2019 erleben die beiden Nationen eine schwerwiegende Feindseligkeit mit den Vereinigten Staaten. Für China waren diese Probleme die Überwachung des Südchinesischen Sees, die Handelspolitik und die Technologie-Politik. Für die andere Partei waren die grössten Ursachen die harschen finanzielle Sanktionen die von der USA und der EU auf Russland gesetzt wurden aufgrund der Annektierung von Krim in der Ukraine. Diese Annektierung wird nicht nur von den Vereinigten Staaten und der Europäischen Union schlecht angesehen, sondern es wurde auch nicht von China anerkannt. Sei es aus Protestgründen oder aus anderen Gründen, die chinesischen territoriale Ansprüche im Südchinesischen See Raum werden nicht unterstützt.  </p>
            <img src={ process.env.PUBLIC_URL + "/images/crimea.jpg"} className="textImage"/>
            <p className="textBlock">Im Bezug zum aktuellen Ukraine-Russland Krieg und der chinesischen Meinung dazu wird sehr viel spekuliert. Laut dem westlichen Spionageprogramm wusste China schon vorher, dass Russland die Ukraine invadieren würde und auch laut «The Beijing Times» wurde ihnen schon im Vorhinein aufgefordert, dass sie nichts negatives über Russland schreiben würden. Während der Westen Russland sehr strikte ökonomische Sanktionen setzt, lockert China deren Regelungen und entlasten somit die russische Ökonomie. Da sie eine so gute Beziehung haben, hat China auch entschieden eine neutrale Meinung im Bezug zum Krieg zu behalten und dies obwohl sie selbst ebenfalls russische Propaganda propagierten.  </p>
        </div>
    );

}

export default ap_russia;