import React from "react";
import '../App.css';


function polit_grund(){
    document.title = "CCP-UA: Politische Grundhaltung"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 24.11.2022</p>
                <p className="infoBlock">Geändert: 2.1.2023</p>
            </div>
            <h1 className="title">Politische Grundhaltung der chinesischen Regierung</h1>
            <p className="textBlock">China war vor dem Ukrainekrieg wirtschaftlich wie auch politisch stark verbunden mit Russland, also wird man wahrscheinlich erwarten, dass China auch die Meinungen von Russland unterstützt. Dies ist jedoch nicht der Fall, da China nicht ihre Beziehung mit westlichen Ländern zerstören will. Deshalb versuchet die chinesische Regierung, die KPCh, einen Mittelgrund zu finden, mit dem sie kein Opfer westlicher Sanktionen, aber immer noch Handelspartner von Russland sein werden. Sie machen keine starken Aussagen für irgendwelche Seite. So sagt die KPCh zum Beispiel, sie seien besorgt um die Zivilisten in der Ukraine. Dies ist nicht eine leere Aussage, sondern China liefert auch ein wenig humanitäre Hilfe. China hat auch mehrere Male erwähnt, sie seien für einen Waffenstillstand in der Ukraine und wollen andere Länder auch dazu bewegen. Obwohl China eher auf der Seite Russlands ist, weil sie nicht wollen, dass ihre wirtschaftliche Konkurrenz stärker wird, befürworten sie die ‘Souveränität’ und ‘territoriale Integrität’ der Ukraine. Das bedeutet, die momentanen (vor dem Krieg) Grenzen sollen respektiert werden.</p>
            <p className="textBlock">Auch Medien sind sehr vorsichtig mit ihrer Wortwahl, wenn es um den Ukrainekrieg geht. So heisst es zum Beispiel nicht, dass es eine ‘Invasion’ ist, sondern eine ‘Spezielle Militaristische Operation’. Was sie aber nicht zulassen wollen, ist, Empathie zu den Ukrainern zu geben.</p>
            <img src={ process.env.PUBLIC_URL + "/images/newspaper.jpg"} className="textImage"/>
            <p className="textBlock">Als das Thema vom Bucha-Massaker (Genozid an Ukrainern in einer kleinen Stadt) angesprochen wurde, bestritt es die KPCh und beschuldigte die Amerikaner, dass sie es veranstaltet hätten. Auch sagten sie, dass der Westen und die USA Russland zum Krieg provozierten, weil die Sicherheit Russlands ignoriert wurde. China beschuldigte die USA, dass sie der Grund sind, dass der Krieg so lange dauert, was natürlich selbstverständlich ist, denn NATO will nicht, dass die Ukraine verliert. Die Beschuldigungen an die Vereinigten Staaten enden jedoch nicht hier, die KPCh sagte auch, die USA benutze die Sanktionen um mehr Macht im wirtschaftlichen Markt zu bekommen.</p>
            <img src={ process.env.PUBLIC_URL + "/images/bucha.jpg"} className="textImage"/>
            <p className="textBlock">Im Übrigen erwähnte China auch, dass ihre Krise mit Taiwan nicht vergleichbar mit der Ukrainekrise sei, da Taiwan kein wirklicher Staat sei.</p>
        </div>
    );

}

export default polit_grund;