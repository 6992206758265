import * as React from "react";
import * as ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import '../App.css';
import {World} from './World'


function ap_main() {
    document.title = "CCP-UA: AP-Home"
    return (
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 12.12.2022</p>
                <p className="infoBlock">Geändert: 15.12.2022</p>
            </div>
            <h1>Aussenpolitik Chinas (im Bezug zum Ukrainekrieg)</h1>
            <p>Bitte klicken Sie auf eines der rot markierten Nationen</p>
            <World />
        </div>
    );

}

export default ap_main;