import React from "react";
import '../App.css';
import Slideshow from "./Slideshow";



function homepage(){
    document.title = "CCP-UA: Home"
    return(
        <div>
            <div className="dateInfos">
                <p className="infoBlock">Erstellt: 8.12.2022</p>
                <p className="infoBlock">Geändert: 11.1.2023</p>
            </div>
            <h1 className="title">China und der Ukrainekrieg</h1>
            <Slideshow />
            <p className="textBlock">Willkommen zu unserer Webseite über die Auswirkungen des Ukrainekrieges auf China. Wir behandeln hier die Themen der Wirtschaft, der Politischen Grundhaltung und der aussenpolitischen Beziehungen mit der USA, Russland und Ukraine aus der Sicht von China.</p>
            <p className="textBlock">Diese Webseite wurde entwickelt von Roman Frank, Leandro Carvalho, Emil Udupuzhayil, Dmitrii Subeichin</p>

            <h2>Weitere Empfehlungen</h2>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ue21IHPpdPg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <p>Quelle: <a href="https://www.youtube.com/watch?v=ue21IHPpdPg" >https://www.youtube.com/watch?v=ue21IHPpdPg</a></p>
        </div>
    );

}

export default homepage;